<template>
	<v-card>
		<v-card-title class="history-title">
			<v-spacer></v-spacer>
			<v-btn icon @click="$emit('closeDialog')">
			<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text class="history-text">
			<div v-if="data.length === 0" style="text-align: center;">Нет истории запуска</div>
			<v-data-table v-else
				:headers="headers"
				:items="tableList"
			>
		</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>

export default {
	props: {
		data: {
			type: Array,
			default: () => ([])
		}
	},

	data () {
		return {
			headers: [
				{
					text: 'Дата и время',
					value: 'date'
				},
				{
					text: 'ПС',
					value: 'source'
				},
				{
					text: 'Источник',
					value: 'vendor'
				},
				{
					text: 'Кэш',
					value: 'cash'
				},
				{
					text: 'Email',
					value: 'email'
				}
			]
		}
	},

	computed: {
		tableList () {
			return this.data.map(el => ({
				...el,
				date: el.timestamp,
				source: el.source.charAt(0).toUpperCase() + el.source.slice(1),
				vendor: el.vendor.charAt(0).toUpperCase() + el.vendor.slice(1),
				cash: el.is_force ? 'off' : 'on',
				email: el.username
			}))
		}
	}
}
</script>

<style scoped>
	.history-text {
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		text-align: left;
		color: rgba(0, 0, 0, 1) !important;
	}

	.history-title {
		padding: 0 !important;
	}
</style>
