<template>
	<v-card >
		<v-card-title>
			{{ title }}
		</v-card-title>
		<v-card-text>
			<div class="collection-card">
				<div class="collection-card_project-data">
					{{ data.id }}<br>
					{{ data.name }}<br>
					{{ data.region }}<br>
					{{ data.device === 'Desktop' ? 'Десктоп' : 'Мобильный' }}<br>
					{{ data.requests }} запросов
				</div>
				<div class="collection-card_date-data">
					<div class="collection-time">
						Текущее время: {{ today }}
					</div>

				</div>
			</div>
			<v-checkbox
					:class="{ 'hidden': !isRootUser }"
					:ripple="false"
					v-model="cash"
					hideDetails
					label="Использовать кеш"
				/>
			<div class="cash-description" :class="{ 'hidden': !isRootUser }">При использовании кеша, будут использованы позиции уже собранные сегодня для других проектов платформы  смежными запросами</div>
			<div class="collection-card_collection-control">

				<div>
					<v-btn
					class="collection-btn cancel-btn"
					@click="$emit('closeDialog')"
					>
						Отмена
					</v-btn>
					<v-btn
						class="collection-btn start-btn"
						@click="makeStartData(data)"
					>
						Запустить
					</v-btn>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { getUser } from '@/function/auth.js'
import { mapActions } from 'vuex'

export default {
	props: {
		data: {
			type: Object
		},
		title: {
			type: String
		},
		today: {
			type: String
		}
	},

	data () {
		return {
			isRootUser: getUser().isRoot(),
			cash: true
		}
	},

	methods: {
		...mapActions({
			postForceUpdate: 'postForceUpdate'
		}),

		makeStartData (item) {
			const data = {
				item: item,
				cash: this.cash
			}
			this.$emit('showSureDialog', data)
		}
	}
}
</script>

<style scoped>
	.hidden {
		visibility: hidden;
	}
	.collection-card {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	.collection-card_project-data {
		color:black;
	}

	.collection-card_date-data {
		color:black;
		text-align: right;
	}

	.collection-card_collection-control {
		display: flex;
		justify-content: right;

	}
	.collection-btn {
		width: 97px;
		height: 40px;
		color: white;
		background-color: rgba(254, 67, 67, 1) !important;
		box-shadow: none !important;
		text-transform: none !important;
	}
	.cancel-btn {
		background-color: rgba(186, 177, 177, 1) !important;
	}
	.start-btn {
		margin-left: 20px;
	}
	.autocoplele-item {
		margin-top: 15px;
	}
	.collection-time{
		margin-right: 15px;
	}
	.radio-group {

	}
	.v-input--selection-controls {
		margin-top:0;
	}
	.radio-btn_container {
		margin-bottom: 15px;
	}

</style>
