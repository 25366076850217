<template>
	<v-card>
		<v-card-title>
			{{ title }}
		</v-card-title>
		<v-card-text>
			<div class="collection-card">
				<div class="collection-card_project-data">
					{{ data.item.id }}<br>
					{{ data.item.name }}<br>
					{{ data.item.region }}<br>
					{{ data.item.device === 'Desktop' ? 'Десктоп' : 'Мобильный' }}<br>
					{{ data.item.requests }} запросов
				</div>
				<div class="collection-card_date-data">
					Текущее время:{{ today }}
				</div>
			</div>
			<div class="finish-area">
				<v-btn
				class="collection-btn understand-btn"
				@click="$emit('closeDialog')"
			>
				Понятно
			</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>

export default {
	props: {
		data: {
			type: Object
		},
		title: {
			type: String
		},
		today: {
			type: String
		}
	}
}
</script>

<style scoped>
.collection-btn {
		width: 97px;
		height: 40px;
		color: white;
		background-color: rgba(254, 67, 67, 1) !important;
		box-shadow: none !important;
		text-transform: none !important;
	}
	.collection-card {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	.collection-card_project-data {
		color:black;
	}

	.collection-card_date-data {
		color:black;
		text-align: right;
	}
	.finish-area{
		display: flex;
		justify-content: right;
	}
	.understand-btn {
		background-color: rgba(41, 121, 255, 1) !important;
	}
</style>
