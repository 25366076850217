<template>
	<v-card >
		<v-card-title>
			{{ title }}
		</v-card-title>
		<v-card-text>
			<div class="collection-card">
				<div class="collection-card_project-data">
					{{ data.item.id }}<br>
					{{ data.item.name }}<br>
					{{ data.item.region }}<br>
					{{ data.item.device === 'Desktop' ? 'Десктоп' : 'Мобильный' }}<br>
				</div>
				<div class="collection-card_date-data">
					<div >
					Текущее время: {{ today }}
				</div>
				<div>
					Использовать кеш: {{ data.cash ? 'да' : 'нет'}}
				</div>
				</div>
			</div>
			<div class="requests-count"><strong>{{ data.item.requests }}</strong>&nbsp;запросов</div>
			<div class="collection-card_project-data">Откатить нажатие нельзя - механизмы запустятся, ракеты полетят</div>
			<div class="collection-card_collection-buttons">
					<v-btn
					class="collection-btn cancel-btn"
					@click="$emit('closeDialog')"
					>
						Отмена
					</v-btn>
					<v-btn
						class="collection-btn start-btn"
						@click="startCollection(data)"
					>
						Запустить
					</v-btn>
				</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { getUser } from '@/function/auth.js'
import { mapActions } from 'vuex'

export default {
	props: {
		data: {
			type: Object
		},
		title: {
			type: String
		},
		today: {
			type: String
		}
	},

	data () {
		return {
			isRootUser: getUser().isRoot()
		}
	},

	methods: {
		...mapActions({
			postForceUpdate: 'collections/postForceUpdate'
		}),

		async startCollection (item) {
			const request = !this.data.cash ? this.postForceUpdate({ project_id: item.item.id, is_force: 1 }) : this.postForceUpdate({ project_id: item.item.id })
			try {
				const success = await request
				if (success) {
					this.$emit('showFinishDialog')
				}
			} catch (error) {
				this.$emit('showErrorDialog', error)
			}
		}
	}
}
</script>

<style scoped>
	.hidden {
		visibility: hidden;
	}
	.collection-card {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	.collection-card_project-data {
		color:black;
	}

	.collection-card_date-data {
		color:black;
		text-align: right;
	}

	.collection-card_collection-control {
		display: flex;
		justify-content: space-between;

	}
	.collection-btn {
		width: 97px;
		height: 40px;
		color: white;
		background-color: rgba(254, 67, 67, 1) !important;
		box-shadow: none !important;
		text-transform: none !important;
	}
	.cancel-btn {
		background-color: rgba(186, 177, 177, 1) !important;
	}
	.start-btn {
		margin-left: 20px;
	}
	.collection-card_collection-buttons {
		display: flex;
		justify-content: right;
	}

	.requests-count {
		display: flex;
      justify-content: center;
		color: black;
		font-size: 16px;
		margin-bottom: 15px;
	}

</style>
