import { render, staticRenderFns } from "./projectsCollection.vue?vue&type=template&id=76d640ed&scoped=true"
import script from "./projectsCollection.vue?vue&type=script&lang=js"
export * from "./projectsCollection.vue?vue&type=script&lang=js"
import style0 from "./projectsCollection.vue?vue&type=style&index=0&id=76d640ed&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76d640ed",
  null
  
)

export default component.exports