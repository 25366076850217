<template>
	<v-card>
		<v-card-title>
			{{ title }}
		</v-card-title>
		<v-card-text>
			<div class="collection-card">
				{{ data }}
			</div>
			<div class="finish-area">
				<v-btn class="understand-btn" @click="$emit('closeDialog')">
					Понятно
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>

export default {
	props: {
		title: {
			type: String
		},
		data: {
			type: String
		}
	}
}
</script>

<style scoped>
	.collection-card {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	.finish-area{
		display: flex;
		justify-content: right;
	}
	.understand-btn {
		width: 97px;
		height: 40px;
		color: white;
		box-shadow: none !important;
		text-transform: none !important;
		background-color: rgba(41, 121, 255, 1) !important;
	}
</style>
