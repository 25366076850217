<template>
	<div>
		<h1 class="main-title">Сбор вне расписания</h1>
		<br/>
		<div class="collection">
			<div class="collection-header">
				<div class="collection-header_item">
					<div class="collection-header_item_name">Клиент</div>
					<v-autocomplete
						class="autocoplele-item"
						v-model="filters.client_id"
						:items="clients"
						outlined
						dense
						clearable
						hideDetails
						light
						item-text="name"
						item-value="id"
						label="Выберите клиента"
						:filter="filterFunction"
						:loading="clientLoading"

					>
					<template v-slot:item="{ item }">
						<span><sup>{{item.id}}</sup> {{item.name}}</span>
					</template>
					</v-autocomplete>

				</div>
				<div class="collection-header_item">
					<div class="collection-header_item_name">Проект</div>
					<v-select-project
					v-model="filters.project_id"
					:loading="projectLoading"
					:clientId="selectedClientId"
					:cacheItems="false"
				/>
				</div>
			</div>
			<div class="collection-buttons">
				<v-btn
				class="collection-buttons_item all_projects-btn"
				:class="{ active: isClickedAll }"
				@click="onAllButtonClick"
				>
					Все проекты
				</v-btn>
				<v-btn
				class="collection-buttons_item with-history_projects-btn"
				:class="{ active: isClickedHistory }"
				@click="onHistoryButtonClick"
				>
					Проекты с историей запуска
				</v-btn>
			</div>
			<v-data-table
				v-if="showTab && !projectLoading"
				class="collection-table"
				:headers="headers"
				:items="tableList"
				:headerStyle="{ backgroundColor: 'lightblue' }"
				:loading="projectLoading"
			>
			<!-- eslint-disable-next-line vue/valid-v-slot-->
				<template v-slot:item.collection="{ item }">
					<v-tooltip top :disabled="item.is_button_active">
						<template v-slot:activator="{ on }">
							<div v-on="on" style="display: inline-block;">
								<v-btn
									class="collection-btn"
									:disabled="!item.is_button_active"
									@click="handleEdit(item)"
								>
									Запустить
								</v-btn>
							</div>
						</template>
						<span>Сбор по расписанию или ручной сбор уже запущен</span>
					</v-tooltip>
					<v-btn
						icon
						@click="onHistoryIconClick (item.id)"
					>
						<v-icon>
							mdi-history
						</v-icon>
					</v-btn>
				</template>
			</v-data-table>

			<template v-if="isClickedHistory && !filters.client_id && !filters.project_id && ! tableList.length && !projectLoading">
				<div class="choice-message">Проекты не найдены</div>
			</template>

			<template v-if="!showTab && !projectLoading && !isClickedHistory">
				<div class="choice-message">Для отображения списка проектов выберите клиента или проект</div>
			</template>

			<v-dialog  max-width="510px"
				v-model="showDialog"
			>
				<component
					:is="dialogProps.component"
					:data="dialogProps.data"
					:title="dialogProps.title"
					:today="dialogProps.today"
					@closeDialog="closeDialog"
					@showSureDialog="showSureDialog"
					@showFinishDialog="showFinishDialog(dialogProps.data)"
					@showErrorDialog="showErrorDialog"
				></component>
			</v-dialog>
			<v-dialog  max-width="700px"
				v-model="showHistoryDialog"
			>
				<component
					:is="dialogProps.component"
					:data="dialogProps.data"
					@closeDialog="closeDialog"
				></component>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import selectProject from '@/components/selectProject'
import dialogStart from './components/dialogStart'
import dialogFinish from './components/dialogFinish'
import dialogHistory from './components/dialogHistory'
import dialogSure from './components/dialogSure'
import dialogError from './components/dialogError.vue'

export default {
	components: {
		'v-select-project': selectProject,
		'v-dialog-start': dialogStart,
		'v-dialog-finish': dialogFinish,
		'v-dialog-history': dialogHistory,
		'v-dialog-sure': dialogSure,
		'v-dialog-error': dialogError
	},
	data () {
		return {
			isClickedAll: true,
			isClickedHistory: false,
			selectedClientId: undefined,
			headers: [
				{
					text: 'ID',
					sortable: false,
					value: 'id'
				},
				{
					text: 'Наименование про',
					sortable: false,
					value: 'name'
				},
				{
					text: 'Регион',
					sortable: false,
					value: 'region'
				},
				{
					text: 'Устройство',
					sortable: false,
					value: 'device'
				},
				{
					text: 'Всего запросов',
					sortable: false,
					value: 'requests'
				},
				{
					text: 'Клиент',
					sortable: false,
					value: 'client'
				},
				{
					text: 'Сбор вне расписания',
					sortable: false,
					value: 'collection'
				}
			],
			filters: {
				client_id: null,
				project_id: null
			},
			showTab: false,
			clientLoading: true,
			projectLoading: true,
			showDialog: false,
			dialogProps: {},
			showHistoryDialog: false
		}
	},

	computed: {
		...mapState({
			clients: state => state.collections.clients,
			projects: state => state.options.projects,
			projectHistory: state => state.collections.forceProjectHistory
		}),

		...mapGetters({
			forceProjects: 'collections/forceProjects'
		}),

		urlFilters () {
			return { ...this.$route.query }
		},

		projectList () {
			return this.filters.client_id ? this.forceProjects : this.projects.list
		},

		tableList () {
			return this.forceProjects.map(el => ({
				...el,
				id: el.project_id,
				name: el.project_name,
				region: el.regions.yandex.region_name,
				device: el.device === 'Desktop' ? 'Десктоп' : 'Мобильный',
				requests: el.semantics_count,
				client: el.client_name
			}))
		}
	},

	async mounted () {
		try {
			await this.setFilters()
			await this.getClients()
		} catch (error) {
			this.$notify({ type: 'error', title: error })
		} finally {
			this.clientLoading = false
			this.projectLoading = false
		}
	},

	methods: {
		...mapActions({
			getProjects: 'getProjects',
			getForceProjects: 'collections/getForceProjects',
			getClients: 'collections/getClients',
			getForceProjectHistory: 'collections/getForceProjectHistory',
			postForceUpdate: 'collections/postForceUpdate'
		}),

		async setFilters () {
			if (this.urlFilters.client_id) {
				this.filters.client_id = +this.urlFilters.client_id
			}
			if (this.urlFilters.project_id) {
				this.filters.project_id = +this.urlFilters.project_id
			}
		},

		/* Обновление URL */
		async updateUrl (query) {
			await this.$router.replace({
				query: {
					...this.$route.query,
					...query
				}
			}).catch((_) => {})
			this.$route.query.client_id || this.$route.query.project_id ? this.showTab = true : this.showTab = false
		},

		filterFunction (item, queryText, itemText) {
			return itemText.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1 || item.id.toString().indexOf(queryText.trim().toString()) > -1
		},

		async onAllButtonClick () {
			this.isClickedHistory = false
			this.isClickedAll = true

			if (this.$route.query.client_id) {
				this.projectLoading = true
				await this.getForceProjects({
					client_id: this.$route.query.client_id,
					limit: 1000
				})
				this.projectLoading = false
			}

			if (this.$route.query.project_id && !this.$route.query.client_id) {
				this.projectLoading = true
				await this.getForceProjects({
					project_id: this.$route.query.project_id,
					limit: 1000
				})
				this.projectLoading = false
			}

			if (this.$route.query.project_id && this.$route.query.client_id) {
				this.projectLoading = true
				await this.getForceProjects({
					project_id: this.$route.query.project_id,
					client_id: this.$route.query.client_id,
					limit: 1000
				})
				this.projectLoading = false
			}

			if (!this.$route.query.project_id && !this.$route.query.client_id) {
				this.showTab = false
			}
		},

		async onHistoryButtonClick () {
			this.isClickedHistory = true
			this.isClickedAll = false
			this.showTab = true

			if (this.$route.query.project_id && this.$route.query.client_id) {
				this.projectLoading = true
				await this.getForceProjects({
					client_id: this.$route.query.client_id,
					project_id: this.$route.query.project_id,
					limit: 1000,
					is_history: 1
				})
				this.projectLoading = false
			} else if (this.$route.query.project_id && !this.$route.query.client_id) {
				this.projectLoading = true
				await this.getForceProjects({
					project_id: this.$route.query.project_id,
					limit: 1000,
					is_history: 1
				})
				this.projectLoading = false
			} else if (this.$route.query.client_id && !this.$route.query.project_id) {
				this.projectLoading = true
				await this.getForceProjects({
					client_id: this.$route.query.client_id,
					limit: 1000,
					is_history: 1
				})
				this.projectLoading = false
			} else if (!this.$route.query.client_id && !this.$route.query.project_id) {
				this.projectLoading = true
				await this.getForceProjects({
					limit: 1000,
					is_history: 1
				})
				if (!this.forceProjects.length) {
					this.showTab = false
				}
				this.projectLoading = false
			}
		},

		today () {
			let today = new Date()
			const hh = String(today.getHours())
			const mm = String(today.getMinutes())
			const ss = String(today.getSeconds())
			const dd = String(today.getDate()).padStart(2, '0')
			const MM = String(today.getMonth() + 1).padStart(2, '0')
			const yyyy = today.getFullYear()

			today = hh + ':' + mm + ':' + ss + ' ' + dd + '.' + MM + '.' + yyyy
			return today
		},

		handleEdit (item) {
			this.showDialog = true
			this.dialogProps = {
				title: 'Вы запускаете сбор вне расписания',
				data: item,
				component: 'v-dialog-start',
				today: this.today()
			}
		},

		async onHistoryIconClick (itemId) {
			await this.getForceProjectHistory({ project_id: itemId })
			this.showHistoryDialog = true
			this.dialogProps = {
				data: this.projectHistory,
				component: 'v-dialog-history'
			}
		},

		closeDialog () {
			this.showDialog = false
			this.showHistoryDialog = false
		},

		showSureDialog (data) {
			this.dialogProps = {
				title: 'Вы точно-преточно хотите запустить сбор?',
				data: data,
				component: 'v-dialog-sure',
				today: this.today()
			}
		},

		showFinishDialog (item) {
			this.showDialog = false
			this.$nextTick(() => {
				this.dialogProps = {
					title: 'Сбор вне расписания запущен',
					data: item,
					component: 'v-dialog-finish',
					today: this.today()
				}
				this.showDialog = true
			})
		},
		showErrorDialog (error) {
			this.showDialog = false
			this.$nextTick(() => {
				this.dialogProps = {
					title: 'Не получилось запустить сбор',
					data: error,
					component: 'v-dialog-error'
				}
				this.showDialog = true
			})
		}
	},

	watch: {
		'$route.query' (val, oldVal) {
			if (val.project_id !== oldVal.project_id && this.filters.project_id != val.project_id) {
				this.filters.project_id = val.project_id || null
			}
			if (val.client_id !== oldVal.client_id && this.filters.client_id != val.client_id) {
				this.filters.client_id = val.client_id || null
			}
		},

		'filters.client_id': {
			async handler (val, oldVal) {
				if (val !== oldVal) {
					this.selectedClientId = val
					await this.updateUrl({
						client_id: val || undefined,
						project_id: undefined
					})
					if (val && this.isClickedHistory) {
						this.projectLoading = true
						await this.getForceProjects({
							client_id: val,
							limit: 1000,
							is_history: 1
						})
						this.projectLoading = false
					} else if (val && !this.isClickedHistory) {
						this.projectLoading = true
						await this.getForceProjects({
							client_id: val,
							limit: 1000
						})
						this.projectLoading = false
					} else if (!val && this.isClickedHistory) {
						this.projectLoading = true
						await this.getForceProjects({
							limit: 1000,
							is_history: 1
						})
						this.showTab = true
						this.projectLoading = false
					}
				}
			}
		},

		'filters.project_id': {
			async handler (val, oldVal) {
				if (val !== oldVal) {
					await this.updateUrl({
						project_id: val || undefined
					})
				}
				// если выбран только проект, без клиента
				if (val) {
					this.projectLoading = true
					await this.getForceProjects({ project_id: val })
					this.projectLoading = false
				}
				// если был выбран клиент и его проект, но проект убрали
				if (this.$route.query.client_id && !this.$route.query.project_id) {
					this.projectLoading = true
					await this.getForceProjects({ client_id: this.$route.query.client_id, limit: 1000 })
					this.projectLoading = false
				}

				if (this.$route.query.project_id && this.isClickedHistory) {
					this.projectLoading = true
					await this.getForceProjects({
						project_id: this.$route.query.project_id,
						limit: 1000,
						is_history: 1
					})
					this.projectLoading = false
				}

				// если был выбран проект, но проект убрали
				if (!this.$route.query.client_id && !this.$route.query.project_id && this.isClickedHistory) {
					this.projectLoading = true
					await this.getForceProjects({
						limit: 1000,
						is_history: 1
					})
					this.showTab = true
					if (!this.forceProjects.length) {
						this.showTab = false
					}
					this.projectLoading = false
				}
			}
		}
	}
}
</script>

<style scoped>
	.hidden {
		visibility: hidden;
	}

	.collection-header {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 20px;
	}

	.collection-header_item_name {
		font-size: 12px;
		line-height: 16px;
		color: rgba(125, 125, 125, 1);
		margin-bottom: 5px;
	}

	.collection-buttons {
		display: flex;
		flex-direction: row;
	}

	.collection-buttons_item {
		background-color: #FFFFFF !important;
		border: 1px solid rgba(0, 0, 0, 0.12);
		box-shadow: none !important;
	}

	.collection-buttons_item.active {
		background-color: rgba(241, 247, 253, 1) !important;
		color: rgba(49, 82, 255, 1);
	}

	.all_projects-btn {
		border-radius: 4px 0 0 4px;
	}

	.with-history_projects-btn {
		border-radius:  0 4px 4px 0;
	}

	.collection-table {
		margin-top: 30px;
		border-top: 1px solid rgba(0,0,0,.12);
	}

	.v-data-table >>> .v-data-table-header {
		background-color:rgba(0, 0, 0, 0.06) !important;
		color: rgba(125, 125, 125, 1) !important;
		font-weight: 400 !important;
	}

	.collection-btn {
		width: 97px;
		height: 40px;
		color: white;
		background-color: rgba(254, 67, 67, 1) !important;
		box-shadow: none !important;
		text-transform: none !important;
	}

	.choice-message {
		position: absolute;
		width: 775px;
		height: 29px;
		top: 319px;
		left: 157px;
		font-size: 24px;
		font-weight: 400;
		line-height: 29.05px;
		text-align: center;
	}

</style>
